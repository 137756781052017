<template>
  <div class="login-wrapper">
    <div>
      <div class="path">
        <div class="login-logo">
          <img src="../../../public/img/_avatar180.jpg" alt="">
        </div>
      </div>
      <div class="login-area">
        <div class="form-content">
          <div class="fadeIn first pt-3 pb-3 enter-text">
            Тизимга кириш
          </div>
          <form @submit.prevent="auth">
            <input
                :disabled="otpSection"
                v-model="login"
                id="login"
                placeholder="Логин"
                type="text"
                class="fadeIn second">
            <input
                :disabled="otpSection"
                v-model="password"
                id="password"
                placeholder="Парол"
                type="password"
                class="fadeIn third"
                @keyup.enter="auth">
            <input
                v-if="otpSection"
                v-model="otp"
                id="otp"
                placeholder="Тасдиқлаш коди"
                type="text"
                class="fadeIn third"
                @keyup.enter="auth">
            <input
                :disabled="disabled"
                placeholder="Логин"
                type="submit"
                @click="auth"
                class="fadeIn fourth"
                value="Кириш">
            <small
                class="fadeIn text-light clearfix"
                v-if="otpSection"
            >Сизнинг телефон рақамингизга код юборилди</small> <br>
            <a @click="auth" class="resend-sms"
               id="resend-sms"
               v-if="otpSection"
            ><i class="icon-reload"></i>Қайта код жўнатиш</a>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TokenService from "../../services/TokenService";
export default {
  name: "login",
  data(){
    return{
      login:null,
      password:null,
      otp:null,
      otpSection:false,
      disabled:false,
    }
  },
  methods:{
    async auth(){
      this.disabled = true;
      let deviceKey = localStorage.getItem('_deviceKey')
      if (!deviceKey){
        deviceKey = '_' + (Math.random().toString(36) + window.navigator.userAgent).toString(36).substr(2, 9);
        localStorage.setItem('_deviceKey', deviceKey);
      }
      let params = {
        Login: this.login,
        Password: this.password,
        Otp:this.otp
      }
      try {
        const res = await this.$http.post('Admin/loginAdmin', params,{headers:{device_id:deviceKey}});
        if (res) {
          let response = res.result
          if (response.isAuthorized == true){
            TokenService.setToken(response.authorization);
            await this.$router.push('/user');
          }
          else {
            this.otpSection = true
          }


        }
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: e.response.data.error.message
        });
      }finally {
        this.disabled = false
      }
    }
  }
}
</script>

<style scoped>
.login-wrapper{
  background: #262626;
  min-height: 100vh;
}
.login-wrapper .path{
  position: relative;
  width: 100%;
  height: 226px;
  background-color: #1a1a1a;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.login-logo{
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 60px;
}
.login-logo img{
  display: flex;
  margin: 0 auto;
  margin-top: 125px;
  border-radius: 50%;

}
.login-area{
  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}
.login-area .form-content{
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #1a1a1a;
  width: 280px;
  max-width: 384px;
  padding: 0;
  -webkit-box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%);
  box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%);
  text-align: center;
}

.fadeIn{
  /*opacity: 0;*/
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}
.enter-text{
  color: white;
}
.login-wrapper input[type=text][data-v-2e72ad78]:focus {
  color: #fff;
  background-color: rgba(80, 80, 80, 0.4);
  border-bottom: 2px solid rgba(80, 80, 80, 0.4);
}
.login-wrapper input[type=password],
.login-wrapper input[type=text] {
  background-color: rgba(80, 80, 80, 0.4) !important;
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  width: 80%;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.login-wrapper input[type=button], input[type=reset], input[type=submit] {
  width: 80%;
  height: 30px;
  background-color: #409EFF;
  border: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 6px;
  margin: 20px;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
input:focus{
  outline: none;
}
.login-wrapper input::placeholder{
  color: white;
}
.login-logo-title{
  font-size: 36px;
  color: white;
  font-family: "Segoe UI Historic";
}
.el-form-item{
  padding: 0 20px;
  margin-left: 0px;
}
.el-form-item input{
  width: 100%;

}
.el-input__inner{
  background-color: rgba(80,177,217,.2) !important;
}
.enter-text{
  font-size: 20px;
  margin: 20px 0;
}
.login_submit_btn{
  width: 100%;
  color: #fff;
}
.resend-sms{
  color: white;
  cursor: pointer;
}
.resend-sms:hover{
  color: rgba(255,255,255, 0.6);
}
.text-light{
  color: white;
  width: 100%;
  text-align: center;
}
</style>
